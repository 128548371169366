const state={
  herbalNames: [],//中药方剂
  menus: [],//菜单
  compositionNames: [],//药品成分
  drugNames: [],//药品
  userInfo: [],//用户信息
  typeList:[],//菜单字母分类
}
const mutations = {
  herbalNames(state, params) {
      state.herbalNames= params;
  },
  menus(state, params) {
      state.menus= params;
  },
  compositionNames(state, params) {
      state.compositionNames= params;
  },
  drugNames(state, params) {
      state.drugNames= params;
  },
  userInfo(state, params) {
      state.userInfo= params;
  },
  typeList(state, params) {
      state.typeList= params;
  }
}
export default {
  namespaced: true,
  state,
  mutations
}