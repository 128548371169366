import jsCookie from "js-cookie";
import { Message } from "element-ui";

let Compatibility = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  /**
   * 获取用药审查验证
   * @param {any} success
   * @param {any} error
   */
  this.Compatibilitys = function (selectChecks, success, error) {
    var url = ServiceBaseAddress + "/api/Compatibility/Query";
    var list = [];
    if (jsCookie.get("CompatibilityList")) {
      list = JSON.parse(jsCookie.get("CompatibilityList"));
    }
    if (list.length < 2 && selectChecks.indexOf(0) > -1) {
      Message.error("请至少选择两种药品进行配伍！");
      return false;
    }
    let res = [];
    list.forEach(item => {
      res.push({
        key: item.key,
        type: item.type,
      });
    });
    var params = {
      items: res,
      types: selectChecks,
    };
    TokenClient.Post(
      url,
      false,
      { "Content-Type": "application/json" },
      params,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.MZCompatibilitys = function (params, success, error) {
    var url = ServiceBaseAddress + "/api/Compatibility/Query";
    TokenClient.Post(
      url,
      false,
      { "Content-Type": "application/json" },
      params,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  /**
   * 获取药品、成分、食疗药膳、中药、中药方剂名称
   * @param {any} success
   * @param {any} error
   */
  this.SearchAll = function (name, success, error) {
    var url = ServiceBaseAddress + `/api/DrugPublic/SearchAll/${name}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取配伍详情数据
   * @param {any} success
   * @param {any} error
   */
  // this.Detail = function (sourceType, sourceId, targetType, targetId, success, error) {
  //   var param = `/${sourceType}/${sourceId}/${targetType}/${targetId}`;
  //   var url = ServiceBaseAddress + "/api/Compatibility" + param;
  //   TokenClient.Get(
  //     url,
  //     false,
  //     null,
  //     null,
  //     function (data) {
  //       if (success) {
  //         var jsonObj = JSON.parse(data);
  //         success(jsonObj);
  //       }
  //     },
  //     error
  //   );
  // };
  this.Detail = function (sourceType, sourceId, targetType, targetId, success, error) {
    // var url = ServiceBaseAddress + '/api/Compatibility';
    var url = ServiceBaseAddress + "/api/Compatibility/Query";
    let res = [
      {
        key: sourceId,
        type: sourceType,
      },
      {
        key: targetId,
        type: targetType,
      },
    ];
    var params = {
      items: res,
      types: [0],
    };
    TokenClient.Post(
      url,
      false,
      { "Content-Type": "application/json" },
      params,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
};

export { Compatibility };
