import { Message, MessageBox } from 'element-ui'
import routes from '../../router/index'
let ApiClient = function () { }

/**
 * 发送Get请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 * */
ApiClient.Get = function (url, sync, header, param, success, error) {
    Send('GET', url, sync, header, param, success, error);
}
/**
 * 发送Post请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 * */
ApiClient.Post = function (url, sync, header, param, success, error) {
    Send('POST', url, sync, header, param, success, error);
}
/**
 * 发送Put请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 * */
ApiClient.Put = function (url, sync, header, param, success, error) {
    Send('PUT', url, sync, header, param, success, error);
}
/**
 * 发送Delete请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 * */
ApiClient.Delete = function (url, sync, header, param, success, error) {
    Send('DELETE', url, sync, header, param, success, error);
}
/**
 * application/x-www-form-urlencoded
 * */
ApiClient.UrlEncodedHeader = {
    'Content-Type': 'application/x-www-form-urlencoded'
};
/**
 * 初始化XMLHttpRequest
 * */
function InitialRequest(success, error) {
    var XHR = [  //兼容不同浏览器和版本得创建函数数组
        function () { return new XMLHttpRequest() },
        function () { return new ActiveXObject("Msxml2.XMLHTTP") },
        function () { return new ActiveXObject("Msxml3.XMLHTTP") },
        function () { return new ActiveXObject("Microsoft.XMLHTTP") }
    ];
    var xmlHttp = null;
    //尝试调用函数，如果成功则返回XMLHttpRequest对象，否则继续尝试
    for (var i = 0; i < XHR.length; i++) {
        try {
            xmlHttp = XHR[i]();
        } catch (e) {
            continue  //如果发生异常，则继续下一个函数调用
        }
        break;  //如果成功，则中止循环
    }
    xmlHttp.onreadystatechange = function () {
        RequestResultHandle(xmlHttp, success, error);
    };
    return xmlHttp;  //返回对象实例
}
/**
 * 发送数据
 * @param {any} method 发送数据的方法，POST、PUT、GET、DELETE
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 * */
function Send(method, url, sync, header, param, success, error) {
    var xmlHttp = InitialRequest(success, error);
    method = method.toUpperCase();
    var formatParam = ForamtData(param, header);
    try {
        if (method == 'POST' || method == 'PUT') {
            xmlHttp.open(method, url, sync);
            SetRequestHeader(xmlHttp, header);
            xmlHttp.send(formatParam);
        } else {
            xmlHttp.open(method, url + (formatParam ? '?' + formatParam : ''), sync);
            SetRequestHeader(xmlHttp, header);
            xmlHttp.send(null);
        }
    }
    catch (err) {
        console.log(err);
    }
}
/**
 * 设置请求头
 * @param {any} xmlHttp XMLHttpRequest
 * @param {any} header 请求头
 * */
function SetRequestHeader(xmlHttp, header) {
    if (header) {
        for (var key in header) {
            xmlHttp.setRequestHeader(key, header[key]);
        }
    }
}
/**
 * 返回消息处理
 * @param {any} xmlHttp XMLHttpRequest
 * @param {any} success 操作成功回调函数
 * @param {any} error 操作失败回调函数
 * */
function RequestResultHandle(xmlHttp, success, error) {
    if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
            if (success && typeof success === 'function') {
                success(xmlHttp.response);
            }
        } else {
            if (error && typeof error === 'function') {
                var errorInfo = {
                    status: xmlHttp.status,
                    text: xmlHttp.statusText,
                    msg: xmlHttp.response
                };
                error(errorInfo);
            }
            if (xmlHttp.status == "401" && xmlHttp.getAllResponseHeaders().indexOf("revoke_token") > -1) { 
                Message.error('账号在别处登录，请重新登录！')
                routes.replace({ path: '/' }).catch(err => { console.log(err) })
                // MessageBox.confirm('该账号在别处登录，非本人操作？请重亲登录！', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                //   }).then(() => {
                    // routes.replace({ path: '/' }).catch(err => { console.log(err) })
                //   }).catch(() => {
                             
                //   });
            }
            // if (xmlHttp.status == "401") {
            //     Message.error('token过期')
            // }
            if (xmlHttp.status == "403") {
                Message.error('暂无权限，请联系管理员!')
            }
        }
        xmlHttp.onreadystatechange = undefined;
        xmlHttp = null;
    }
}
/**
 * 请求参数格式化
 * @param {any} param 请求参数
 * */
function ForamtData(param, header) {
    var data = '';
    if (header['Content-Type'] == 'application/x-www-form-urlencoded') {
        var params = [];
        for (var key in param) {
            if (!!param[key] || param[key] === 0) {
                params.push(key + '=' + param[key]);
            }
        };
        data = params.join('&');
    } else if (header['Content-Type'] == 'application/json') {
        data = JSON.stringify(param);
    }
    return data;
}

export { ApiClient }