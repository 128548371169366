import jsCookie from "js-cookie";
import { GroupBy } from "../utils/extend";

//配伍js
//Compare对比栏操作

const ckOptions = [
  {
    name: "配伍",
    value: 0,
  },
  {
    name: "禁忌",
    value: 1,
  },
  {
    name: "饮食",
    value: 2,
  },
  {
    name: "老人",
    value: 3,
  },
  {
    name: "儿童",
    value: 4,
  },
  {
    name: "妊娠",
    value: 5,
  },
  {
    name: "哺乳",
    value: 6,
  },
];
//获取名字
export function getckOptionsNames() {
  var names = [];
  ckOptions.forEach(function (item) {
    names.push(item.name);
  });
  return names;
}
//name value 转换
export function nameConvertValue(stringArr) {
  // var values = ''
  var values = [];
  ckOptions.forEach(function (item) {
    if (stringArr.indexOf(item.name) != -1)
      // values = values + item.value + '|'
      values.push(item.value);
  });
  return values;
}

//name value 转换
export function valueConvertName(selectChecks) {
  var names = [];
  ckOptions.forEach(function (item) {
    if (selectChecks.indexOf(item.value) != -1) {
      names.push(item.name);
    }
  });
  return names;
}

//添加
export function addCompare(hashKey, name, status, type) {
  //   var line = jsCookie.get("CompatibilityList");
  //   if (typeof line == "undefined") line = [];
  //   if (line.indexOf(hashKey + "|" + name) >= 0) {
  //     return false;
  //   }
  // var newstr = hashKey + '|' + name + '|' + status
  // switch (type) {
  // 	case 'drug':
  // 		newstr = newstr + '|' + '1'
  // 		break
  // 	case 'herbal':
  // 		newstr = newstr + '|' + '2'
  // 		break
  // }

  //   if (line.length > 2) newstr = line + "||" + newstr; // newstr += '||' + line;
  //   jsCookie.set("CompatibilityList", newstr, 3);
  var list = [];
  if (jsCookie.get("CompatibilityList")) {
    list = JSON.parse(jsCookie.get("CompatibilityList"));
  }
  if (list.findIndex(item => item.key == hashKey) > -1) {
    return false;
  }
  let typeNum = 0;
  switch (type) {
    case "Drug": //药品
      typeNum = 1;
      break;
    case "drug": //药品
      typeNum = 1;
      break;
    case "FoodMaterialPrescription": //食疗药膳
      typeNum = 3;
      break;
    case "foodMaterialPrescription": //食疗药膳
      typeNum = 3;
      break;
    case "HerbalPrescription": //中药方剂
      typeNum = 2;
      break;
    case "herbalPrescription": //中药方剂
      typeNum = 2;
      break;
    case "Material": //中药材
      typeNum = 4;
      break;
    case "material": //中药材
      typeNum = 4;
      break;
    case "HealthyDiet": //健康饮食
      typeNum = 5;
      break;
    case "healthyDiet": //健康饮食
      typeNum = 5;
      break;
  }
  list.push({
    type: typeNum,
    key: hashKey,
    name: name,
    status: status,
  });
  jsCookie.set("CompatibilityList", list, 3);
  return true;
}
//删除
export function removeCompare(hashKey) {
  //   var line = jsCookie.get("CompatibilityList");
  //   if (typeof line == "undefined") line = "";
  //   var arrLine = line.split("||");
  //   for (var pp = 0; pp < arrLine.length; pp++) {
  //     var arrLine2 = arrLine[pp].split("|");
  //     if (arrLine2[0] == hashKey) {
  //       arrLine.splice(pp, 1);
  //     }
  //   }
  //   var result = arrLine.join("||");
  var list = [];
  if (jsCookie.get("CompatibilityList")) {
    list = JSON.parse(jsCookie.get("CompatibilityList"));
  }
  list.splice(
    list.findIndex(item => item.key === hashKey),
    1
  );
  jsCookie.set("CompatibilityList", list, 3);
}
//全部清空
export function removeCompareAll() {
  //   var line = jsCookie.get("CompatibilityList");
  //   if (typeof line == "undefined") line = "";
  jsCookie.remove("CompatibilityList");
}

//更新
export function updateCompare(hashKey, status) {
  var list = [];
  if (jsCookie.get("CompatibilityList")) {
    list = JSON.parse(jsCookie.get("CompatibilityList"));
  }
  let index = list.findIndex(item => item.key === hashKey);
  list[index].status = status;
  jsCookie.set("CompatibilityList", list, 3);
}

//获取列表
export function getCompatibilityList() {
  var list = [];
  if (jsCookie.get("CompatibilityList")) {
    list = JSON.parse(jsCookie.get("CompatibilityList"));
  }
  let res = [];
  list.forEach(item => {
    res.push({
      hashKey: item.key,
      value: item.name,
      state: item.status,
      type: item.type,
    });
  });
  return res;
}

//tip分组
export function tipGroup(list) {
  if (list) {
    const results = GroupBy(list, function (item) {
      return [item.categoryCode];
    });
    var dic = [];
    results.forEach(function (item) {
      var interaction = {
        keys: item[0].categoryCode,
        value: item,
      };
      dic.push(interaction);
    });
    return dic;
  }
}

//名字HTML转换
export function namehtmlraw(item) {
  var result1 = item.drug1Name == null ? (item.item1Name == null ? (item.item1CategoryName == null || item.item1CategoryName == "" ? item.item1Remark + "类中药" : item.item1CategoryName) : item.item1Remark == null || item.item1Remark == "" ? item.item1Name : `${item.item1Name}（${item.item1Remark}）`) : item.drug1Name;
  var result2 = item.drug2Name == null ? (item.item2Name == null || item.item2Name == "" ? item.item2CategoryName : item.item2Name) : item.drug2Name;
  var result = "";
  switch (item.categoryCode) {
    case 0:
    case 1:
    case 2:
    case 4:
      result = `${result1} 与 ${result2} ： ${item.content ? item.content.replace(/\n/g, "<br>") : '' }`;
      break;
    case 5:
      result = `${result1} 与 ${result2} ：两药不宜合用`;
      break;
    case 8:
      result = `${result1} 与 ${result2} ：${item.content ? item.content.replace("结果：", "").replace(/\n/g, "<br>") : ''}`;
      break;
  }
  return result;
}

//饮食禁忌 分组
export function dietGroup(list) {
  const results = GroupBy(list, function (item) {
    return [item.target];
  });
  var dic = [];
  results.forEach(function (item) {
    var interaction = {
      keys: item[0].target,
      value: item,
    };
    dic.push(interaction);
  });
  return dic;
}

//内容HTML转换
export function contenthtmlRaw(item, isdiet = false) {
  var info = "";
  if (!isdiet) {
    if (item.target) {
      if (item.content.indexOf("\n") > -1) {
        info = `<p><b>${item.target}</b></p><p>${item.content.replace(/\n/g, "</p><p>")}</p>`;
      } else {
        info = `<p><b>${item.target}</b>：${item.content}</p>`;
      }
    } else {
      info = `<p>${item.content.replace(/\n/g, "</p><p>")}</p>`;
    }
  } //饮食禁忌
  else {
    info = `<p><b>${item.keys}</b></p>`;
    item.value.forEach(function (i) {
      if (i.content.indexOf("\n") > -1) {
        info += `<p>${i.content.replace(/\n/g, "</p><p>")}</p><p></p>`;
      } else {
        info += `<p>${i.content}</p>`;
      }
    });
  }
  info += "<p>&nbsp;</p>";
  return info;
}

//特殊人群列表
export function getcrowdList(instructions) {
  var result = [];
  if (instructions.length > 0) {
    if (instructions.filter(i => i.type == 3).length > 0) {
      var laoren = instructions.filter(i => i.type == 3)[0].items;
      if (laoren) {
        result.push({
          tag: "老人用药",
          value: laoren,
        });
      }
    }
    if (instructions.filter(i => i.type == 4).length > 0) {
      var ertong = instructions.filter(i => i.type == 4)[0].items;
      if (ertong) {
        result.push({
          tag: "儿童用药",
          value: ertong,
        });
      }
    }
    if (instructions.filter(i => i.type == 5).length > 0) {
      var renchen = instructions.filter(i => i.type == 5)[0].items;
      if (renchen) {
        result.push({
          tag: "妊娠用药",
          value: renchen,
        });
      }
    }
    if (instructions.filter(i => i.type == 6).length > 0) {
      var buru = instructions.filter(i => i.type == 6)[0].items;
      if (buru) {
        result.push({
          tag: "哺乳用药",
          value: buru,
        });
      }
    }
  }
  return result;
}
