import { ApiClient } from './ApiClient.js';

/**
 * Ȩ����֤���Ķ˵㷢�ַ���
 * @param {any} serverAddr Ȩ����֤���ķ����ַ
 */
let Discovery = function (serverAddr) {
    /**
     * Ȩ����֤���ķ����ַ
     * */
    let ServerAddress = serverAddr;
    /**
    * Ȩ����֤���Ķ˵���Ϣ��ַ
    * */
    let ServerDiscovery = ServerAddress + '/.well-known/openid-configuration';
    /**
    * �ϴ�����ʱ��
    * */
    let RequestTime = 0;
    /**
    * �˵���Ϣ
    * */
    let DiscoveryResult = null;
    /**
    * ���ݻ�����Ч��
    * */
    this.Interval = 600;
    /**
     * �˵㷢���ĵ�
     * */
    this.Document = function () {
        var _this = this;
        var currertTime = new Date();
        var time = sessionStorage.getItem('DiscoveryDocumentRequestTime');
        if (time) {
            RequestTime = new Date(time);
        }
        var duration = (currertTime - RequestTime) / 1000;
        if (duration >= _this.Interval) {
            RequestDocument();
        } else {
            var documentData = sessionStorage.getItem('DiscoveryDocument');
            if (documentData) {
                DiscoveryResult = new DiscoveryDocument(documentData);
            }
            else {
                RequestDocument();
            }
        }
        return DiscoveryResult;
    }

    function RequestDocument() {
        ApiClient.Get(ServerDiscovery, false, ApiClient.UrlEncodedHeader, null, function (data) {
            RequestTime = new Date();
            DiscoveryResult = new DiscoveryDocument(data);
            sessionStorage.setItem('DiscoveryDocument', data);
            sessionStorage.setItem('DiscoveryDocumentRequestTime', RequestTime);
        }, null);
    }
}
/**
 * �˵㷢���ĵ�
 * @param {any} json ���������ص�����
 */
let DiscoveryDocument = function (json) {
    var result = undefined;
    switch (typeof json) {
        case 'string':
            result = JSON.parse(json);
            break;
        case 'object':
            result = json;
            break;
    }

    this.Issuer = result?.issuer;
    this.JwksUri = result?.jwks_uri;
    this.AuthorizationEndpoint = result?.authorization_endpoint;
    this.TokenEndpoint = result?.token_endpoint;
    this.UserinfoEndpoint = result?.userinfo_endpoint;
    this.EndSessionEndpoint = result?.end_session_endpoint;
    this.CheckSessionIframe = result?.check_session_iframe;
    this.RevocationEndpoint = result?.revocation_endpoint;
    this.IntrospectionEndpoint = result?.introspection_endpoint;
    this.DeviceAuthorizationEndpoint = result?.device_authorization_endpoint;
    this.FrontchannelLogoutSupported = result?.frontchannel_logout_supported;
    this.FrontchannelLogoutSessionSupported = result?.frontchannel_logout_session_supported;
    this.BackchannelLogoutSupported = result?.backchannel_logout_supported;
    this.BackchannelLogoutSessionSupported = result?.backchannel_logout_session_supported;
    this.ScopesSupported = result?.scopes_supported;
    this.ClaimsSupported = result?.claims_supported;
    this.GrantTypesSupported = result?.grant_types_supported;
    this.ResponseTypesSupported = result?.response_types_supported;
    this.ResponseModesSupported = result?.response_modes_supported;
    this.TokenEndpointAuthMethodsSupported = result?.token_endpoint_auth_methods_supported;
    this.IdTokenSigningAlgValuesSupported = result?.id_token_signing_alg_values_supported;
    this.SubjectTypesSupported = result?.subject_types_supported;
    this.CodeChallengeMethodsSupported = result?.code_challenge_methods_supported;
    this.RequestParameterSupported = result?.request_parameter_supported;
}

export { Discovery }