import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import localData from './modules/localData' //引入 localData 文件
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    localData
  },
  state:{
    isRead :false,
  },
  getters,
  mutations:{
    messageData(state,data){
      state.isRead = data
    }
  }
})
export default store