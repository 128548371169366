<template>
  <div class="navheader">
    <div class="topbar">
      <div class="topbar-box clearfix">
        <div class="topbar-l">
          <!-- <p><img src="../assets/img/tel_icon.png" alt="">咨询热线:<span>18639512888</span>or E-mail: <i>3248344350@qq.com</i></p>-->
        </div>
        <div class="personalCenter">
          <div class="login-box" v-if="!userId">
            <p class="login-txt">
              <router-link to="/login"><i class="el-icon-edit"></i>登录</router-link>
            </p>
          </div>
          <div class="" v-else>
            <div style='display:flex; align-items: center;'>
              <div class="mesTopBox"
                style="display:flex;align-items: center;cursor: pointer;position: relative;margin-right: 5px;position: relative;"
                @click="gomessIndex">
                  <i class="el-icon-bell" style="font-size:20px"></i>
                  <p style="margin-left:10px">消息通知</p>
                  <div class="mesTopItem" v-if="IsHomeMessage">
                </div>
              </div>
              <div class="login-box">
                <p class="login-txt">
                  <router-link to="/personal/index">
                    <i class="mini-head">
                      <img v-if="userhead == '' || userhead == null" src="../assets/img/head_img.png" alt="" />
                      <img v-else :src="Services.Authorization + userhead" alt="" />
                    </i>
                    {{ userName }}<span v-if="roleId == 3">医生</span>
                  </router-link>
                </p>
                <div class="per-hover">
                  <div class="head-box">
                    <div class="head-img">
                      <img v-if="userhead == '' || userhead == null" src="../assets/img/head_img.png" alt="" />
                      <img v-else :src="Services.Authorization + userhead" alt="" />
                    </div>
                    <p>{{ userName }}<span v-if="roleId == 3">医生</span></p>
                  </div>
                  <ul class="per-info clearfix">
                    <li>
                      <router-link to="/personal/referral">
                        <div class="s-icon s-icon1"></div>
                        <p>我的转诊</p>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/personal/treated">
                        <div class="s-icon s-icon3"></div>
                        <p>我的接诊<span class="layui-badge-dot"></span></p>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/personal/SelfTest">
                        <div class="s-icon s-icon2"></div>
                        <p>测试记录</p>
                      </router-link>
                    </li>
                  </ul>
                  <div class="status">
                    <span><router-link to="/personal/index" style="color: #1fb7a6">个人中心</router-link></span>
                    <span><a href="javascript:void(0);" @click="out">退出登录</a></span>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <!-- <div class="feedback test" v-if="userType == '1'">
            <div class="test" @click="goInterrogationIndex1" >
              <i class="el-icon-notebook-2" style="font-size: 15px; margin-bottom: -5px; font-weight: 400;margin-right: 5px;"></i>
              <span>门诊</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="nav-wrap">
      <nav class="clearfix">
        <div class="navbar">
          <ul>
            <li v-for="(item, index) in menuList" :key="index">
              <template v-if="item.url == '/'">
                <template v-if="item.name == '诊断' || item.name == '药品库'">
                  <a href="javascript:void(0);">{{ item.name }}<i class="el-icon-caret-bottom"></i></a>
                </template>
                <template v-else>
                  <a href="javascript:void(0);">{{ item.name }}</a>
                </template>
                <dl class="sub-nav">
                  <dt v-for="(subItem, i) in item.children" :key="i">
                    <router-link v-if="subItem.url != undefined" :to="subItem.url">{{ subItem.name }}</router-link>
                  </dt>
                </dl>
              </template>
              <template v-else>
                <router-link v-if="item.url != undefined" :to="item.url">{{ item.name }}</router-link>
              </template>
            </li>
            <!-- <li @click="goInterrogationIndex">辨病论治</li> -->
            <!-- <li @click="goInterShopIndex">商城</li> -->
          </ul>
        </div>
        <div class="logo">
            <img v-if="orgimgkeyPathData2" :src=" orgimgkeyPathData2.orgimgkeyPath2" alt="" />
              <img v-else-if="getSchoolName" :src="getSchoolName" alt="" />
              <img v-else src="../assets/img/logo.png" alt="" />
          <!-- <img src="../assets/img/logo.png" alt="" /> -->
          <!-- <img src="../assets/img/gylogo01.png" alt="" /> -->

        </div>
      </nav>
    </div>
    <!-- <touch></touch> -->
  </div>
</template>

<script>
import touch from "../components/touch";
import storage from "../utils/customstorage";
import { loginOut } from "../utils/userInfo";
import { Menus } from "./Menu/Menu";
import { MessageDomain } from '../components/personal/Message';
// import { is } from "core-js/core/object";
export default {
  name: "navheader",
  inject: ["reload"],
  components: {
    touch,
  },
  props: {
    getSchoolName:String
  },
  data() {
    var menu = new Menus(this.TokenClient, this.Services.Authorization);
    var MessageDomainTemp = new MessageDomain(this.TokenClient, this.Services.Authorization);
    return {
      menuDomain: menu,
      Result: MessageDomainTemp,
      menuList: [],
      userType: this.$store.state.localData.userInfo.detailUserType,
      userId: this.$store.state.localData.userInfo.id,
      userName: this.$store.state.localData.userInfo.name,
      userhead: this.$store.state.localData.userInfo.photo,
      roleId: this.$store.state.localData.userInfo.roleId,
      activeName: 'first',
      homeMessage: [],
      orgimgkeyPathData2:'',
      IsHomeMessage: this.$store.state.isRead
    };
  },
  created() {
     this.orgimgkeyPathData2 = JSON.parse(localStorage.getItem('orgimgData2')) ? JSON.parse(localStorage.getItem('orgimgData2')) : ''
    this.getMenus();
    this.getHomeMessageData()
  },
  methods: {
      getHomeMessageData() {
        let _this = this;
        _this.Result.GetHomeMessage(function (data) {
            let readNumber = 0
            for (let i=0; i<data.data.length; i++) {
            readNumber = readNumber + data.data[i].unreadCount
            }
            if (readNumber > 0) {
            _this.$store.commit('messageData', true)
            } else {
            _this.$store.commit('messageData', false)
            }
        }, function (error) {
            console.log(error);
        })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getMenus() {
      var _this = this;
      _this.menuDomain.GetMenu(
        _this.roleId,
        function (data) {
          let keys = Object.keys(data.data)[0];
          _this.menuList = data.data[keys];
        },
        function (err) {
          console.log(err);
        }
      );
    },
    out() {
      loginOut();
      this.$message.success("操作成功");
      this.reload();
    },
    gomessIndex() {
      this.$router.push({
        name: 'messageIndex',
        query: {
          typeCom: 0
        }
      })
    },
    goInterrogationIndex() {
      this.$router.push({
        name: 'diseasePage'
      })
    },
    goInterrogationIndex1() {
      let url = this.$router.resolve({
        name: "interrogationIndex",
      });
      window.open(url.href, "_blank");
    },
    goInterShopIndex() {
      let url = this.$router.resolve({
        name: "shopPage",
      });
      window.open(url.href, "_blank");
    },
  },
  mounted() {
    this.$bus.$off("userhead");
    this.$bus.$on("userhead", msg => {
      if (msg) {
        this.userhead = msg;
      } else {
        this.userhead = "";
      }
    });
  },
};
</script>

<style scoped>
.navheader .topbar {
  background: #f6f6f6;
  height: 45px;
}

.navheader .topbar .topbar-box {
  width: 1200px;
  margin: 0 auto;
}

.navheader .topbar-l {
  float: left;
}

.navheader .topbar-l p {
  font-size: 12px;
  color: #333;
  line-height: 45px;
  height: 45px;
}

.navheader .topbar-l p span {
  color: #068679;
  font-size: 16px;
  padding: 0 10px;
}

.navheader .topbar-l p i {
  color: #222222;
  font-size: 14px;
  font-style: normal;
}

.navheader .personalCenter {
  /* margin: 0 50px; */
  position: relative;
  float: right;
  font-size: 14px;
  color: #333;
  line-height: 45px;
}

.navheader .personalCenter>div {
  display: inline-block;
  vertical-align: top;
}

.navheader .personalCenter .login-box>p {
  width: 150px;
  text-align: center;
  height: 45px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navheader .personalCenter .login-box>p .mini-head {
  display: inline-block;
  margin-right: 10px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}

.navheader .personalCenter .login-box>p .mini-head img {
  width: 100%;
  height: 100%;
}

.navheader .personalCenter .login-box:hover .per-hover {
  display: block;
}

/* .navheader .personalCenter .mesTopBox:hover .mesTopHover {
  display: block;
} */

.mesTopItem {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #EB1E1E;
  position: absolute;
  left: 12px;
  top: 12px;
}

.navheader .mesTopBox .mesTopHover {
  display: none;
  position: absolute;
  top: 40px;
  left: -75px;
  width: 300px;
  height: 250px;
  background: #ffffff;
  border-radius: 3px;
  padding: 0 20px;
  box-shadow: 5px 0px 8px #d9d9d9;
  z-index: 9;
}

.navheader .feedback {
  width: 150px;
  font-size: 14px;
  color: #ffffff;
  background: #01c2ac;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}

.navheader .test {
  width: 150px;
  font-size: 14px;
  color: #ffffff;
  background: orange;
  text-align: center;
  line-height: 45px;
  height: 45px;
  cursor: pointer;
}

.navheader .feedback a {
  color: #ffffff;
}

.navheader .feedback i {
  margin-right: 10px;
  line-height: 48px;
  height: 45px;
  /* font-weight: bold;/ */
}

.navheader .feedback span a {
  color: #ffffff;
}

.navheader .per-hover {
  display: none;
  position: absolute;
  left: -75px;
  width: 300px;
  height: 250px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 5px 0px 8px #d9d9d9;
  z-index: 9;
}

.navheader .head-box {
  padding-top: 15px;
  background: #f9f9f9;
  text-align: center;
}

.navheader .head-img {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.navheader .head-img img {
  width: 100%;
  height: 100%;
}

.navheader .per-info li {
  margin: 5px 10px;
  float: left;
  width: 76px;
  height: 68px;
}

.navheader .per-info li p {
  line-height: 1;
  text-align: center;
}

.navheader .per-info li:hover {
  background: #1fb7a6;
  border-radius: 5px;
}

.navheader .per-info li:hover p {
  color: #ffffff;
}

.navheader .s-icon {
  width: 25px;
  height: 25px;
  margin: 10px auto;
}

.navheader .s-icon1 {
  background: url("../assets/img/s_icon1.jpg") center no-repeat;
}

.navheader .s-icon2 {
  background: url("../assets/img/s_icon2.jpg") center no-repeat;
}

.navheader .s-icon3 {
  background: url("../assets/img/s_icon3.png") center no-repeat;
}

.navheader .per-info li:hover .s-icon1 {
  background: url("../assets/img/w_icon1.png") center no-repeat;
}

.navheader .per-info li:hover .s-icon2 {
  background: url("../assets/img/w_icon2.png") center no-repeat;
}

.navheader .per-info li:hover .s-icon3 {
  background: url("../assets/img/w_icon3.png") center no-repeat;
}

.navheader .status {
  border-top: 1px solid #ebebeb;
}

.navheader .status span {
  display: inline-block;
  font-size: 14px;
  color: #333;
  width: 49%;
  text-align: center;
  line-height: 60px;
}

/*-----------nav-----------*/
.navheader .nav-wrap {
  background: #ffffff;
  height: 80px;
}

.navheader nav {
  width: 1200px;
  margin: 0 auto;
}

/* .navheader nav .logo {
  float: left;
  width: 200px;
  height: 52px;
  margin-top: 14px;
} */

.navheader nav .navbar {
  float: right;
}
.navheader nav .logo {
  float: left;
  width:auto;
  height: 50px;
  margin-top: 14px;
}
.navheader nav .logo img {
   width:auto;
  height: 50px;
  /* width: 100%;
  height: 100%; */
}
.navheader nav .navbar ul li {
  float: left;
  padding: 20px 30px;
  line-height: 40px;
  position: relative;
}

.navheader nav .navbar ul li:last-child {
  padding-right: 0px;
}

.navheader nav .navbar ul li a {
  display: inline-block;
  position: relative;
  border-width: 0;
  font-size: 16px;
  line-height: 40px;
}

.navheader nav .navbar ul li:hover>a {
  display: inline-block;
  color: #038678;
}

.navheader nav .navbar ul li>a::before {
  content: "";
  position: absolute;
  /* 重点 */
  left: 0;
  /* 重点 */
  bottom: 0;
  /* 重点 */
  width: 0;
  /* 重点 */
  height: 2px;
  background: #038678;
  transition: width 0.5s;
}

.navheader nav .navbar ul li:hover>a::before {
  width: 100%;
}

.navheader nav .navbar ul li.active>a::before {
  width: 100%;
}

.navheader nav .navbar ul li.active>a {
  color: #038678;
}

.navheader nav .navbar ul li:hover .sub-nav {
  display: block;
}

.navheader .sub-nav {
  display: none;
  position: absolute;
  top: 62px;
  padding: 10px 0;
  left: -20%;
  width: 150px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 2px 0px 2px #f0f0f0;
  z-index: 9;
}

.navheader .sub-nav dt a {
  display: block !important;
  text-align: center;
  font-size: 14px !important;
  color: #333;
}

.navheader .sub-nav dt:hover a {
  color: #1fb7a6 !important;
}
</style>
<style>
.navheader .el-badge__content.is-dot {
  height: 6px;
  width: 6px;
}

.navheader .el-badge__content.is-fixed.is-dot {
  right: 0px;
  top: 11px;
}
</style>
