//同类药 页面跳转
import router from '../router'
//跳转同类药-中药详情
export function toMaterial(hashKey) {
	// const href = router.resolve({
	// 	name: 'materialDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		name: 'materialDetail',
		query: {
			hashKey: hashKey,
		},
	});
}
//同类药-中药更多
export function toMaterialMore(categoryId) {
	// const href = router.resolve({
	// 	path: '/Drug/Material',
	// 	query: {
	// 		categoryId: categoryId,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/Drug/Material',
		query: {
			categoryId: categoryId,
		},
	});
}

//跳转同类药-食疗药膳详情
export function toFood(hashKey) {
	// const href = router.resolve({
	// 	name: 'foodDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		name: 'foodDetail',
		query: {
			hashKey: hashKey,
		},
	})
}

//同类药-食疗药膳更多
export function toFoodMore(categoryId) {
	// const href = router.resolve({
	// 	path: '/Drug/Food',
	// 	query: {
	// 		categoryId: categoryId,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/Drug/Food',
		query: {
			categoryId: categoryId,
		},
	})
}

//跳转同类药-中药方剂详情
export function toHerbal(hashKey) {
	// const href = router.resolve({
	// 	name: 'herbalDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		name: 'herbalDetail',
		query: {
			hashKey: hashKey,
		},
	})
}

//同类药-中药方剂更多
export function toHerbalMore(categoryId) {
	// const href = router.resolve({
	// 	path: '/Drug/Herbal',
	// 	query: {
	// 		categoryId: categoryId,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/Drug/Herbal',
		query: {
			categoryId: categoryId,
		},
	})
}

//跳转同类药-中成药、西药详情
export function toDrug(hashKey) {
	// const href = router.resolve({
	// 	path: '/Drug/DrugDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/Drug/DrugDetail',
		query: {
			hashKey: hashKey,
		},
	})
}

//同类药-中成药、西药更多
export function toDrugMore(categoryId) {
	// const href = router.resolve({
	// 	path: '/Drug/DrugBank',
	// 	query: {
	// 		categoryId: categoryId,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/Drug/DrugBank',
		query: {
			categoryId: categoryId,
		},
	})
}

//药品成分查询
export function toCompositionSearch(keyword) {
	// const href = router.resolve({
	// 	path: '/Drug/DrugBank',
	// 	query: {
	// 		keyword: keyword,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/Drug/DrugBank',
		query: {
			keyword: keyword,
		},
	})
}

//跳转疾病详情
export function toDisease(hashKey) {
	// const href = router.resolve({
	// 	path: '/disease/DiseaseDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/disease/DiseaseDetail',
		query: {
			hashKey: hashKey,
		},
	})
}

//跳转穴位详情
export function toAcupoint(hashKey) {
	// const href = router.resolve({
	// 	path: '/disease/MeridianDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 		type: 'Acupoint',
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/disease/MeridianDetail',
		query: {
			hashKey: hashKey,
			type: 'Acupoint',
		},
	})
}
//跳转经络详情
export function toMeridian(hashKey) {
	// const href = router.resolve({
	// 	path: '/disease/MeridianDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 		type: 'Meridian',
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/disease/MeridianDetail',
		query: {
			hashKey: hashKey,
			type: 'Meridian',
		},
	})
}
//跳转推拿手法详情
export function toManipulation(hashKey) {
	// const href = router.resolve({
	// 	path: '/disease/MeridianDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 		type: 'Manipulation',
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/disease/MeridianDetail',
		query: {
			hashKey: hashKey,
			type: 'Manipulation',
		},
	})
}
//跳转健康饮食详情
export function toHealthyDetail(hashKey) {
	// const href = router.resolve({
	// 	path: '/Drug/HealthyDetail',
	// 	query: {
	// 		hashKey: hashKey,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/Drug/HealthyDetail',
		query: {
			id: hashKey,
			searchType: "all"
		},
	})
}
//跳转健康饮食详情
export function toVideoDetail(hashKey) {
	router.push({
		path: '/video/videoDetail',
		query: {
			videoId: hashKey,
		},
	})
}
//头部搜索
export function search(searchType, keyword, allActiveName) {
	switch (searchType) {
		//全站
		case 'all':
			//跳转到详情
			router.push({
				path: '/searchList',
				//name:'foodDetail',
				query: {
					activeName: allActiveName,
					keyWord: keyword,
				},
			})
			break
		//疾病
		case 'disease':
			router.push({
				path: '/disease/DiseaseBank',
				query: {
					searchType: searchType,
					keyword: keyword,
				},
			})
			break
		//症状
		case 'zhengzhuang':
			router.push({
				path: '/disease/DiseaseBank',
				query: {
					searchType: searchType,
					keyword: keyword,
					isClinical: 1,
				},
			})
			break
		//药品
		case 'drug':
			router.push({
				path: '/Drug/DrugBank',
				query: {
					searchType: searchType,
					keyword: keyword,
				},
			})
			break
		//中药
		case 'material':
			router.push({
				path: '/Drug/Material',
				query: {
					searchType: searchType,
					keyword: keyword,
				},
			})
			break
		//中药方剂
		case 'herbal':
			router.push({
				path: '/Drug/Herbal',
				query: {
					searchType: searchType,
					keyword: keyword,
				},
			})
			break
		//食疗药膳
		case 'food':
			//跳转到食疗药膳
			router.push({
				path: '/Drug/Food',
				query: {
					searchType: searchType,
					keyword: keyword,
				},
			})
			break
		//经络穴位
		case 'meridian':
			//跳转到经络穴位
			router.push({
				path: '/disease/MeridianBank',
				query: {
					searchType: searchType,
					keyword: keyword,
				},
			})
			break
			case 'healthyDiet':
			//跳转到健康饮食
			router.push({
				path: '/Drug/HealthyDiet',
				query: {
					searchType: searchType,
					keyword: keyword,
				},
			})
			break
	}
}

//配伍查询
export function tocompare(selectChecks) {
	const href = router.resolve({
		path: '/Compatibility',
		query: {      
			selectChecks: JSON.stringify(selectChecks),
		},
	})
	window.open(href.href, '_blank')
}

//配伍查询
export function tocompareDetail(sourceType, sourceHashKey, targetType, targetHashKey) {
	// const href = router.resolve({
	// 	path: '/CompaDetail',
	// 	query: {
	// 		sourceType: sourceType,
	// 		sourceHashKey: sourceHashKey,
	// 		targetType: targetType,
	// 		targetHashKey: targetHashKey,
	// 	},
	// })
	// window.open(href.href, '_blank')
	router.push({
		path: '/CompaDetail',
		query: {
			sourceType: sourceType,
			sourceHashKey: sourceHashKey,
			targetType: targetType,
			targetHashKey: targetHashKey,
		},
	})
}
//收藏、浏览记录 跳转
export function clickName(name, entityId) {
	switch (name) {
		case 'disease':
			toDisease(entityId, 0, '')
			break
		case 'drug':
			toDrug(entityId, 0, null)
			break
		case 'food':
			toFood(entityId, 0, '')
			break
		case 'herbal':
			toHerbal(entityId, 0, '')
			break
		case 'material':
			toMaterial(entityId, 0, '')
			break
		case 'acupoint':
			toAcupoint(entityId, 0, '')
			break
		case 'meridian':
			toMeridian(entityId, 0, '')
			break
		case 'manipulation':
			toManipulation(entityId, 0, '')
			break
		case 'healthy':
			toHealthyDetail(entityId, 0, '')
			break
		case 'Video':
			toVideoDetail(entityId, 0, '')
			break
	}
}