//扩展js
//扩展分组
export function GroupBy(array, fn) {
  const groups = {};
  array.forEach(function (item) {
    const group = JSON.stringify(fn(item));
    //这里利用对象的key值唯一性的，创建数组
    groups[group] = groups[group] || [];
    groups[group].push(item);
  });
  //最后再利用map循环处理分组出来
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

//获取键值对下的length
export function getPropertyCount(o) {
  console.log(o);
  var n,
    count = 0;
  for (n in o) {
    if (o.hasOwnProperty(n)) {
      count++;
    }
  }
  console.log(count);
  return count;
}
//获取键值对下的length
export function getCount(o) {
  console.log(o);
  var n,
    count = 0;
  for (n in o.items) {
    if (o.hasOwnProperty(n)) {
      count++;
    }
  }
  console.log(count);
  return count;
}

//收藏、浏览记录 名称转换
export function converName(name) {
  var result = "";
  switch (name) {
    case "disease":
      result = "疾病";
      break;
    case "drug":
      result = "药品";
      break;
    case "food":
      result = "食疗药膳";
      break;
    case "herbal":
      result = "中药方剂";
      break;
    case "material":
      result = "中药饮片";
      break;
    case "acupoint":
      result = "穴位";
      break;
    case "meridian":
      result = "经络";
      break;
    case "manipulation":
      result = "推拿手法";
      break;
    case "compatibility":
      result = "配伍";
      break;
    case "healthy":
      result = "健康饮食";
      break;
    case "Video":
      result = "视频";
      break;
  }
  return result;
}
//将秒转换为时分秒
export function FormatSecond(value) {
  var theTime = parseInt(value); // 秒
  var middle = 0; // 分
  var hour = 0; // 小时

  if (theTime > 60) {
    middle = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (middle > 60) {
      hour = parseInt(middle / 60);
      middle = parseInt(middle % 60);
    }
  }
  var result = "" + parseInt(theTime) + "秒";
  if (middle > 0) {
    result = "" + parseInt(middle) + "分" + result;
  }
  if (hour > 0) {
    result = "" + parseInt(hour) + "小时" + result;
  }
  return result;
}
