import { Discovery } from './Discovery.js';
import { ApiClient } from './ApiClient.js';
import storage from "@/utils/customstorage";
/**
 * 权限认证中心客户端
 * @param {any} serverAddr 权限认证中心的服务器地址
 * @param {any} clientId ClientId
 * @param {any} secret Secret
 * @param {any} username 用户名
 * @param {any} password 登陆密码
 * @param {any} loginInfo 登录信息 
 * @param {any} scope ClientScope
 * @param {any} grantType grant_type
 */
let TokenClient = function (serverAddr, clientId, secret, loginInfo, scope) {
    if (!clientId || !secret) throw new Error("参数设置错误");
    var _this = this;
    /**
     * 权限认证中心的服务器地址
     * */
    let ServerAddress = serverAddr;
    /**
    * 客户端配置信息
    * */
    let Client = new ClientInfo(clientId, secret, loginInfo, scope);
    /**
    * 最后一次请求Token的时间
    * */
    let RequestTime = 0;
    /**
    * 服务器发现服务
    * */
    let DiscoveryDocument = new Discovery(ServerAddress);
    /**
    * Token信息
    * */
    let TokenData = null;

    _this.SetClient = async function (clientId, secret, scope) {
        localStorage.removeItem(Client.ClientId + 'Token');
        localStorage.removeItem(Client.ClientId + 'TokenRequestTime');
        Client.ClientId = clientId;
        Client.Secret = secret;
        Client.Scope = scope;
        TokenData = null;
        await _this.Token();
    }
    _this.SetUser = async function (loginInfo) {
        Client.LoginInfo = loginInfo;
        TokenData = null;
        localStorage.removeItem(Client.ClientId + 'Token');
        localStorage.removeItem(Client.ClientId + 'TokenRequestTime');
        await _this.Token();
    }

    _this.IsUser = async function () {
        if (await _this.RefreshToken()) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
    * 从缓存中获取Token或从服务器请求Token
    * */

    let TokenPromise = null; // 用于存储首次或刷新Token请求的Promise
    let isRefreshing = false; // 标记是否正在进行刷新Token操作

    function requestOrRefreshTokenIfNeeded(param) {
        if (!TokenPromise || !TokenData || (TokenData && isTokenExpired())) {
            if (isRefreshing) {
                return TokenPromise; // 如果正在刷新Token，直接返回当前Promise
            }
            isRefreshing = true;
            TokenPromise = new Promise((resolve, reject) => {
                var tokenEndpoint = DiscoveryDocument.Document()?.TokenEndpoint;
                ApiClient.Post(tokenEndpoint, false, ApiClient.UrlEncodedHeader, param, function (data) {
                    RequestTime = new Date();
                    TokenData = new TokenInfo(data);
                    localStorage.setItem(Client.ClientId + 'Token', data);
                    localStorage.setItem(Client.ClientId + 'TokenRequestTime', RequestTime);
                    storage.setItem({
                        name: 'token',
                        value: JSON.parse(data).access_token,
                        expires: RequestTime,  
                    })
                    isRefreshing = false;
                    resolve(TokenData); // 成功获取或刷新Token后resolve
                }, function (error) {
                    isRefreshing = false;
                    reject(error); // 请求或刷新Token失败时reject
                });
            });
        }
        return TokenPromise;
    }

    function isTokenExpired() {
        const tokenExpiresAt = RequestTime.getTime() + TokenData.ExpiresIn * 1000;
        return (new Date().getTime() >= tokenExpiresAt);
    }

    _this.Token = async function () {
        var sessionTokenStr = localStorage.getItem(Client.ClientId + 'Token');
        var sessionTokenRequestTimeStr = localStorage.getItem(Client.ClientId + 'TokenRequestTime');

        // 解析缓存Token信息
        if (sessionTokenRequestTimeStr && sessionTokenRequestTimeStr) {
            RequestTime = new Date(sessionTokenRequestTimeStr);
            TokenData = new TokenInfo(sessionTokenStr);
            //if (TokenData === null) {
            //    TokenData = new TokenInfo(sessionTokenStr);
            //}
        }
        else {
            TokenData = null;
        }

        // 如果Token不存在或已过期，则尝试获取或刷新Token
        if (!TokenData || isTokenExpired()) {
            try {
                TokenData = await requestOrRefreshTokenIfNeeded(
                    TokenData?.RefreshToken ? new TokenRefreshParam(Client, TokenData.RefreshToken) : new TokenRequestParam(Client)
                );
            } catch (error) {
                // 处理Token请求失败的情况
                console.error('Failed to request or refresh Token:', error);
                return null;
            }
        }

        return TokenData;
    }
    /**
    * 获取AccessToken
    * */
    _this.AccessToken = async function () {
        var token = await _this.Token();
        return token?.AccessToken;
    }
    /**
    * 获取RefreshToken
    * */
    _this.RefreshToken = async function () {
        var token = await _this.Token();
        return token?.RefreshToken;
    }
    /**
    * 获取AccessToken的有效期
    * */
    _this.ExpiresIn = async function () {
        var token = await _this.Token();
        return token?.ExpiresIn;
    }
    /**
     * 获取AccessToken的TokenScope
     * */
    _this.TokenScope = async function () {
        var token = await _this.Token();
        return token?.TokenScope;
    }
    /**
    * 获取AccessToken的类型
    * */
    _this.TokenType = async function () {
        var token = await _this.Token();
        return token?.TokenType;
    }
    /**
    * 生成附带AccessToken的Header
    * */
    _this.TokenHeader = async function () {
        var token = await _this.Token();
        if (token && token.AccessToken) {
            return { 'Authorization': token.TokenType + ' ' + token.AccessToken };
        }
        else {
            return {};
        }
    }

    _this.Token();
}
/**
 * 发送Post请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Post = async function (url, sync, header, param, success, error) {
    var tokenHeader = await this.TokenHeader();
    var mergeHeader = Object.assign({}, header, tokenHeader);
    ApiClient.Post(url, sync, mergeHeader, param, success, error);
}
/**
 * 发送Put请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Put = async function (url, sync, header, param, success, error) {
    var tokenHeader = await this.TokenHeader();
    var mergeHeader = Object.assign({}, header, tokenHeader);
    ApiClient.Put(url, sync, mergeHeader, param, success, error);
}
/**
 * 发送Get请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Get = async function (url, sync, header, param, success, error) {
    var tokenHeader = await this.TokenHeader();
    var mergeHeader = Object.assign({}, header, tokenHeader);
    ApiClient.Get(url, sync, mergeHeader, param, success, error);
}
/**
 * 发送Delete请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Delete = async function (url, sync, header, param, success, error) {
    var tokenHeader = await this.TokenHeader();
    var mergeHeader = Object.assign({}, header, tokenHeader);
    ApiClient.Delete(url, sync, mergeHeader, param, success, error);
}
/**
 * 权限认证中心客户端
 * @param {any} clientId
 * @param {any} secret
 * @param {any} username
 * @param {any} password
 * @param {any} scope
 */
let ClientInfo = function (clientId, secret, LoginInfo, scope) {
    this.ClientId = clientId;
    this.Secret = secret;
    this.LoginInfo = LoginInfo;
    this.Scope = scope;
}
/**
 * 申请Token请求参数
 * @param {any} client 客户端配置信息
 */
let TokenRequestParam = function (client) {
    this.client_id = client?.ClientId;
    this.client_secret = client?.Secret;
    this.scope = client?.Scope;
    if (client.LoginInfo && client.LoginInfo.GrantType) {
        switch (client.LoginInfo.GrantType) {
            case 'password':
                this.username = client.LoginInfo.Username;
                this.password = client.LoginInfo.Password;
                break;
            case 'client_credentials':
                break;
            case 'otherLogin_auth':
                this.userAccount = client.LoginInfo.UserAccount;
                this.department = client.LoginInfo.Department;
                this.organizationName = client.LoginInfo.OrganizationName;
                break;
            default:
                break;
        }
        this.grant_type = client.LoginInfo.GrantType;
    }
}
/**
 * 刷新Token请求参数
 * @param {any} client 客户端配置信息
 * @param {any} refreshToken RefreshToken
 */
let TokenRefreshParam = function (client, refreshToken) {
    this.client_id = client?.ClientId;
    this.client_secret = client?.Secret;
    this.grant_type = 'refresh_token';
    this.refresh_token = refreshToken;
}
/**
 * 获取Token结果
 * @param {any} json 获取Token接口返回的值
 */
let TokenInfo = function (json) {
    var result = undefined;
    switch (typeof json) {
        case 'string':
            result = JSON.parse(json);
            break;
        case 'object':
            result = json;
            break;
    }

    this.AccessToken = result?.access_token;
    this.RefreshToken = result?.refresh_token;
    this.ExpiresIn = result?.expires_in;
    this.TokenScope = result?.scope;
    this.TokenType = result?.token_type;
}

export { TokenClient }