<template>
  <div id="app" v-title :data-title="verSion">
    <router-view v-if="isRouterAlive && !$route.meta.keepAlive"></router-view>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      header_show: true,
      footer_show: true,
      isRouterAlive: true,
      verSion: '智能诊疗辅助系统',
      // verSion:'国医互联',
    };
  },
  components: {
    "pc-header": Header,
    "pc-footer": Footer,
  },
  methods: {
    //是否显示头部
    header: function (bool) {
      this.header_show = bool;
    },
    //是否显示底部
    footer: function (bool) {
      this.footer_show = bool;
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  },
  // created() {
  //   setTimeout(() => {
  //     this.getWebSocket();
  //   }, 1000);
  // },
};
</script>
<style>
@import "./assets/css/common.css";

#app {
  width: 100%;
  height: 100%;
}
</style>
