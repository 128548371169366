<template>
    <div id="footer">
        <div class="footer-con">
            <div class="footer-top clearfix flex-center">
                <div class="footer-logo">
                    <img src="../assets/img/logo-footer.png" alt="">
                    <!-- <img src="../assets/img/gylogo02.png" alt="" /> -->
                </div>
                <div class="footer-nav">
                    <router-link to="/index">首页</router-link>
                    <span>-</span>
                    <router-link to="/company/company">公司介绍</router-link>
                    <span>-</span>
                    <router-link to="/company/contactUs">联系我们</router-link>
                    <span>-</span>
                    <a target="_blank" href="/20210712091219.pdf">使用帮助</a>
                    <span>-</span>
                    <router-link to="/company/disclaimer">隐私政策</router-link>
                    <span>-</span>
                    <router-link to="/company/UserAgreement">服务协议</router-link>
                </div>
                <!-- <div class="qrcode clearfix">
                    <div class="left">
                        <img src="../assets/img/qrcode.png" alt="">
                        <p>关注公众号</p>
                    </div>
                    <div class="left">
                        <img src="../assets/img/ampCode.jpg" alt="">
                        <p>微信小程序</p>
                    </div>
                    <div class="left">
                        <img src="../assets/img/code_d3.png" alt="">
                        <p>APP下载(安卓)</p>
                    </div>
                </div> -->
            </div>
            <div class="copyright">
                <p class="imgSrc">
                    <span @click="toImg(4)">互联网药品许可证</span><span class="line">|</span>
                    <span @click="toImg(2)">增值电信业务经营许可证</span>
                    <span class="line">|</span>
                    <span @click="toImg(3)">广播电视台节目制作许可证</span>
                    <span class="line">|</span>
                    <a href="http://ccm.mct.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/de3aa6f9d69d4187b1b25e28f2a043c4"
                        target="view_window">
                        <img class="img-bottom" src="../assets/img/imgIcon.jpg" alt=""></img>
                        <span>网络文化经营许可证</span>
                    </a>
                </p>
                <p>©2021qyyl365.com版权所有<a style="color: #ffffff;" href="https://beian.miit.gov.cn/"
                        target="view_window">豫ICP备2021000748号</a>.千越医疗科技有限公司提供技术支持</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "footerNav"
    ,
    data() {
        return {
        }
    },
    methods: {
        toImg(type) {
            this.$emit('getType',type)
            this.$router.push({
                path: '/imgShow',
                query: {
                    type: type
                }

            })
        }
    },
}
</script>

<style scoped>
@import "../assets/css/footer.css";

.imgSrc span {
    cursor: pointer;
    color: #ffffff !important;
}

.imgSrc span:hover,
a:hover {
    cursor: pointer;
    color: #1fb7a6 !important;
}

span.line {
    margin: 0 5px;
}

.img-bottom {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: -8px 5px;
}

.img {
    width: 100%;
    height: 100%;
}

.copyright p {
    line-height: 30px;
    height: 30px;
}
</style>
