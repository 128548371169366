import Vue from 'vue'
import store from '@/store'
import { Notification  } from 'element-ui'
import router from '../router';
import { TokenClient } from '@/components/tokenclient/TokenClient'
import axios from 'axios'

var url = 'wss://api.qyyl365.net/messagewss/pushmessage'
var loadUrl = 'https://api.qyyl365.net/authorization/api/Message/GetHomeMessage/1'
var ws;
var tt;
var lockReconnect = false;//避免重复连接
var notificationInstance = null
var isScoketFalse = false
var websocket = {
    Init:  function () {
        var _this = this
        if ("WebSocket" in window) {
            ws = new WebSocket(url);
        } else if ("MozWebSocket" in window) {
            ws = new MozWebSocket(url);
        } else {
            console.log("您的浏览器不支持 WebSocket!");
            return;
        }
        ws.onmessage = async function (e) {
          if (JSON.parse(e.data).Code == '401') {
            userToken = await Vue.prototype.TokenClient.AccessToken()
          } else {
            messageHandle(e)
          }
        }
        ws.onclose = function (e) {
          var _this = this
            console.log('断开链接')
            clearInterval(_this.serverTimeoutObj)
            //重连
            // reconnect(_this.url);
        }
        ws.onopen = async function () {
            ws.send(JSON.stringify({
              token: await Vue.prototype.TokenClient.AccessToken()
            }));
            _this.serverTimeoutObj = setInterval(()=>{
              var ping = {
                "msgtype": "0"
              };
              ws.send(JSON.stringify(ping));
            },20000);
        }
        ws.onerror = function (e) {
            console.log(e)
            console.log("数据传输发生错误");
            // var _this = this
            // reconnect(_this.url)
        }
    },
    webClosed: function () {
      isScoketFalse = true
      ws.onclose = function () {
        var _this = this
          console.log('自动退出断开链接')
          clearInterval(_this.serverTimeoutObj)
      }
    }
}

document.addEventListener('visibilitychange', function() {
  if (document.visibilityState === 'hidden') {
      console.log('页签不可见，关闭WebSocket连接');
      // ws.onclose
      if (websocket.webClosed.length != 0) {
        websocket.webClosed()
      }
  } else if (document.visibilityState === 'visible') {
      console.log('页签变为可见，重新建立WebSocket连接');
      websocket.Init();
  }
});

export default websocket;


// export default websocketClose;
//根据消息标识做不同的处理
function messageHandle(e) {
    var _this = this
    getMessage()
    let data = JSON.parse(e.data);
    let messageContent = '';
    if (data.Data) {
      if (data.Data.SubType === 24) {
        messageContent = '查看';
      } else if (data.Data.SubType === 25) {
        messageContent = '去处理';
      } else if (data.Data.SubType === 12){
        messageContent = '去审核';
      } else if (data.Data.SubType === 13){
        messageContent = '去付款';
      } else {
        messageContent = '';
      }
      notificationInstance = Notification ({
        title: data.Data.Title,
        dangerouslyUseHTMLString: true,
        duration: 0,
        position: 'bottom-right',
        message:'<div>'+ data.Data.Content +  '<span style="color: #3d5cff; cursor: pointer;">' +  messageContent + '</span></div>',
        onClick() {
          notifyClick(data.Data.Type, data.Data.SubType);
        },
      });
    }
}
function notifyClick(type, SubType) {
    if (SubType == 24) {
      router.push({
        path: '/personal/referral',
        query: {
          typeCom: 0,
          msgIndex: type,
        }
      })
      notificationInstance.close();
    } else if (SubType == 25) {
      router.push({
        path: '/personal/treated',
        query: {
          typeCom: 0,
          msgIndex: type,
        }
      })
      notificationInstance.close();
    } else if (SubType == 12)  {
     let url = router.resolve({
        path: '/interrogation/PremangePatientList',
      })
      window.open(url.href, "_blank");
      notificationInstance.close();
    }
    else if (SubType == 13)  {
      let url = router.resolve({
         path: '/interrogation/orderList',
       })
       window.open(url.href, "_blank");
       notificationInstance.close();
     } else {
      router.push({
        path: '/messageIndex?typeCom=0',
        query: {
          typeCom: 0
        }
      })
      notificationInstance.close();
     }
  }
function reconnect(sname) {
    if (lockReconnect && !isScoketFalse) {
        return;
    };
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    tt = setTimeout(function () {
        websocket.Init(sname);
        lockReconnect = false;
    }, 4000);
}
async function getMessage () {
  axios({
    method: "GET",
    url: loadUrl,
    headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + await Vue.prototype.TokenClient.AccessToken() },
  })
  .then((res) => {
    let readNumber = 0
    for (let i=0; i<res.data.data.length; i++) {
      readNumber = readNumber + res.data.data[i].unreadCount
    }
    if (readNumber > 0) {
      store.commit('messageData', true)
    } else {
      store.commit('messageData', false)
    }
  })
  .catch((err) => {
    
  });
}
//心跳检测
// var heartCheck = {
//     start: function () {
//         var ping = {
//           "msgtype": "0"
//         };
//         ws.send(JSON.stringify(ping));
//         //这里发送一个心跳 发送失败从错误因素

//     }
// }
