<template>
  <div class="rightBar">
    <ul class="bar-list">
      <li class="bar-item yysc" @click="drawer = true"></li>
      <li class="feedback">
        <el-slider v-if="showSlider" v-model="fontNum" :max="100" :min="0" :step="50" show-stops :format-tooltip="formatTooltip" @change="dataChange"> </el-slider>
      </li>
      <li class="bar-item feedback" @click="changeSize" v-if="isShowChangeSize">
        <img src="../assets/img/right/font_icon.png" alt="" />
        <p>{{ fontSize }}字号</p>
      </li>
      <li class="bar-item feedback">
        <router-link to="/company/feedback">
          <img src="../assets/img/right/feedback_icon.png" alt="" />
          <p>反馈</p>
        </router-link>
      </li>
      <li class="bar-item back">
        <el-backtop :visibility-height="0">
          <div></div>
        </el-backtop>
      </li>
    </ul>
    <el-drawer title="用药审查" :visible.sync="drawer" :before-close="handleClose">
      <div class="drawer-content">
        <el-autocomplete class="inline-input" v-model="state" :fetch-suggestions="querySearchAsync" placeholder="输入关键词" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete>
        <div class="pei-list-auto">
          <ul class="pei-list">
            <li class="on clearfix flex-center" v-for="(item, index) in fruit" :key="index">
              <el-checkbox class="left" :checked="item.state == '1' ? true : false" @change="checked => checkChange(checked, item.hashKey)"></el-checkbox>
              <div class="name left" :title="item.value">
                <a href="javascript:void(0);" target="_blank"> {{ item.value }}</a>
              </div>
              <div class="del-btn right" @click="toRemoveCompar(item.hashKey)">
                <i class="el-icon-close"></i>
              </div>
            </li>
          </ul>
        </div>
        <div class="pei-sel">
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange" style="margin-right: 15px">全选 </el-checkbox>
          <el-checkbox-group v-model="checkedCk" @change="handleCheckedCksChange" style="display: inline">
            <el-checkbox v-for="city in ck" :label="city" :key="city">{{ city }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="btn-group">
          <div>
            <el-button type="primary" round @click="compatibility()">用药审查</el-button>
          </div>
          <div>
            <el-button round @click="removeAll()">全部清空</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import jsCookie from "js-cookie";
import { getDrugOrHerbal } from "../utils/diseaseUtility";
import { getckOptionsNames, addCompare, nameConvertValue, getCompatibilityList, removeCompare, updateCompare, removeCompareAll } from "../utils/Compatibility";
import { tocompare } from "../utils/relation";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui.css";
import { Compatibility } from "../components/Compatibility/Compatibility";
export default {
  name: "right",
  inject: ["reload"],
  data() {
    var compatibility = new Compatibility(this.TokenClient, this.Services.Drug);
    return {
      compatibilityDomain: compatibility,
      drawer: false,
      state: "",
      checkAll: true,
      checkedCk: getckOptionsNames(),
      ck: getckOptionsNames(),
      fruit: getCompatibilityList(),
      selectChecks: [0, 1, 2, 3, 4, 5, 6],
      fontSize: "",
      fontNum: parseInt(sessionStorage.getItem("fontSize")),
      showSlider: false,
      isShowChangeSize: true,
    };
  },
  mounted() {
    var _this = this;
    _this.$bus.$off("right");
    _this.$bus.$on("right", msg => {
      if (msg == "reload") {
        _this.fruit = getCompatibilityList();
      }
    });
    if (sessionStorage.getItem("fontSize") == null) {
      _this.dataChange(50);
    } else {
      _this.dataChange(this.fontNum);
    }
    _this.$bus.$off("fontSize");
    _this.$bus.$on("fontSize", () => {
      _this.dataChange(this.fontNum);
    });
    _this.$bus.$off("isShowChangeSize");
    _this.$bus.$on("isShowChangeSize", () => {
      _this.isShowChangeSize = false;
    });
  },
  methods: {
    formatTooltip(val) {
      if (val < 50) {
        return "小";
      }
      if (val == 50) {
        return "中";
      }
      if (val > 50) {
        return "大";
      }
    },
    dataChange(e) {
      this.fontSize = "中";
      let times = 1;
      if (e < 50) {
        times = 0.8;
        this.fontSize = "小";
      }
      if (e > 50) {
        times = 2;
        this.fontSize = "大";
      }
      this.showSlider = false;
      sessionStorage.setItem("fontSize", e);
      this.setSize(e, times);
    },
    setSize(e, times) {
      if (e < 50) {
        times = 0.8;
      }
      if (e > 50) {
        times = 2;
      }
      if (e == 50) {
        times = 1;
      }
      // 标题
      $(document).ready(function () {
        $("[data-size]").each(function () {
          $(this).css({
            "font-size": $(this).data("size") * times + "px",
            "line-height": "1.8",
          });
          if ($(this).find("*")) {
            $(this)
              .find("*")
              .css({
                "font-size": $(this).data("size") * times + "px",
                "line-height": "1.8",
              });
          }
        });
      });
    },
    changeSize() {
      this.showSlider = !this.showSlider;
    },
    handleClose(done) {
      this.drawer = false;
    },
    handleCheckAllChange(val) {
      if (val) this.selectChecks = [0, 1, 2, 3, 4, 5, 6];
      else this.selectChecks = "";
      this.checkedCk = val ? this.ck : [];
      this.isIndeterminate = false;
    },
    handleCheckedCksChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.ck.length;
      this.selectCheckProcess(value);
    },
    querySearchAsync(queryString, cb) {
      var _this = this;
      if (queryString != "") {
        let callBackArr = [];
        _this.compatibilityDomain.SearchAll(
          queryString,
          function (data) {
            let res = [];
            data.data.forEach(item => {
              if (item.name.indexOf(queryString) > -1) {
                callBackArr.push({
                  value: item.name,
                  id: item.result,
                  type: item.type,
                });
              }
            });
            if (callBackArr.length == 0) {
              cb([{ value: "暂无数据", price: "暂无数据" }]);
            } else {
              cb(callBackArr);
            }
          },
          function (error) {
            console.log("配伍请求异常:", error);
          }
        );
      }
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    loadAll(queryString) {
      var data = getDrugOrHerbal(queryString);
      return data;
    },
    handleSelect(item) {
      if (this.fruit.length >= 1) {
        if (this.fruit.some(i => i.hashKey == item.id)) {
          return false;
        }
      } else {
        this.fruit = [];
      }
      item.state = 1;

      addCompare(item.id, item.value, 1, item.type);
        this.fruit = getCompatibilityList();
       item.state = '';
    },
    toRemoveCompar(hashKey) {
      removeCompare(hashKey);
      this.fruit = getCompatibilityList();
    },
    checkChange(check, hashKey) {
      updateCompare(hashKey, check);
    },
    //选择处理
    selectCheckProcess(stringArr) {
      this.selectChecks = nameConvertValue(stringArr);
    },
    compatibility() {
      if (!this.selectChecks) {
        this.$message.error("请选择配伍类型！");
        return false;
      }
      var list = [];
      if (jsCookie.get("CompatibilityList")) {
        list = JSON.parse(jsCookie.get("CompatibilityList"));
      }
      if (list.length < 2 && this.selectChecks.indexOf(0) > -1) {
        this.$message.error("请至少选择两种药品进行配伍！");
        return false;
      }
      tocompare(this.selectChecks);
    },
    removeAll() {
      removeCompareAll();
      this.fruit = [];
    },
  },
};
</script>
<style scoped>
.rightBar {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 10000;
}

.btn-group {
  margin-top: 20px;
}

.btn-group div {
  margin: 5px 0;
  text-align: center;
}

.pei-list li {
  margin: 15px 0;
}

.pei-list li .name {
  width: 235px;
  line-height: 45px;
  border-bottom: 1px solid #cccccc;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.del-btn {
  line-height: 45px;
  padding: 0 5px;
}

.del-btn i {
  font-size: 18px;
  font-weight: 700;
}

.xi .bar-list .bar-item {
  width: 54px;
  height: 54px;
  border-radius: 5px;
  background: #1fb7a6;
  margin-bottom: 10px;
  box-shadow: 2px 0 5px #999999;
}

.xi .bar-list .bar-item.yysc {
  background: #1fb7a6 url("../assets/img/right/yysc_icon.png") center no-repeat;
  cursor: pointer;
}

.xi .bar-list .bar-item.back {
  background: #1fb7a6 url("../assets/img/right/back_icon.png") center no-repeat;
  cursor: pointer;
}

.zhong .bar-list .bar-item {
  width: 54px;
  height: 54px;
  border-radius: 5px;
  background: #b76c12;
  margin-bottom: 10px;
  box-shadow: 2px 0 5px #999999;
}

.zhong .bar-list .bar-item.yysc {
  background: #b76c12 url("../assets/img/right/yysc_icon.png") center no-repeat;
  cursor: pointer;
}

.zhong .bar-list .bar-item.back {
  background: #b76c12 url("../assets/img/right/back_icon.png") center no-repeat;
  cursor: pointer;
}

.bar-list .bar-item.feedback {
  text-align: center;
  cursor: pointer;
}

.bar-list .bar-item.feedback img {
  margin: 0px auto;
  display: block;
  text-align: center;
  padding: 7px;
}

.bar-list .bar-item.feedback p {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
}

.el-backtop {
  width: 100%;
  height: 100%;
  position: initial;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}

.el-backtop:hover {
  background-color: rgba(0, 0, 0, 0);
}

.sr_a .asi {
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  background: #1fb7a6;
  width: 54px;
  height: 54px;
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 64px;
  box-shadow: 2px 0 5px #999999;
  display: none;
}

.sr_a .asize {
  width: 245px;
  background: #fff;
  background-position: right center;
}

.fsize_slider {
  width: 200px;
  height: 57px;
  float: left;
}

/* .sr_a .asi:hover{ background: #ebebeb; border: 1px #ebebeb solid;} */
.fsize_slider span {
  float: left;
  line-height: 57px;
  width: 44px;
  text-align: center;
  color: #1a1a1a;
}

#slider {
  width: 100px;
  float: left;
  border: none;
  height: 6px;
  background: #70757a;
  position: relative;
  margin-top: 26px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border: 2px #fff solid;
}

#slider .ui-slider-handle {
  width: 16px;
  height: 16px;
  background: #033c88;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-left: -8px;
  margin-top: -9px;
  position: absolute;
  border: 3px #fff solid;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

#slider .ui-slider-range-min {
  background: #0059d0;
}

.sr_a_one:hover .asi {
  width: 200px;
  display: block;
}

.clearfix:after {
  content: "\20";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
}

.clearfix {
  *zoom: 1;
}
</style>
<style>
.rightBar .v-modal {
  display: none !important;
}

.rightBar .el-drawer {
  width: 360px !important;
}

.rightBar .el-drawer__header {
  color: #333;
  padding: 15px 20px;
  background-color: #e0e0e0;
  margin-bottom: 0;
}

.rightBar .drawer-content {
  padding: 20px;
}

.el-autocomplete {
  width: 100%;
}

.rightBar .el-checkbox {
  margin: 0 15px 15px 0 0;
}

.rightBar .pei-list .el-checkbox {
  margin: 0 10px;
}

.pei-sel .el-checkbox {
  margin-right: 15px;
}

.pei-sel {
  margin-top: 10px;
}

.rightBar .el-checkbox__label {
  font-size: 16px;
}

.rightBar .el-checkbox__inner {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #ededed;
}

.rightBar .el-checkbox__inner::after {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 6px;
  top: 3px;
  width: 4px;
}

.rightBar .el-checkbox__input:hover {
  border-color: #dcdfe6;
}

.rightBar .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #dcdfe6;
}
</style>
