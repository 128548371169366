import { DrugExtend } from "../components/Drug/DrugExtend";
import { Extra } from "../components/Disease/Extra";
import router from "../router";
import store from "../store/index";
var tokenClient;
var Services_Drug;
var Services_Disease;

//疾病
var disease = "diseaseNames";
//除去小儿穴位
var acupoint = "acupointNames";
//小儿常用穴位
var acupointSon = "acupointSon";
//经脉
var meridian = "meridianNames";
//药品
var drug = "drugNames";
//药品成分
var composition = "compositionNames";
//食疗药膳
var food = "foodNames";
//中药方剂
var herbal = "herbalNames";
//中药
var material = "materialNames";
//成人推拿手法
var manipulationGrow = "manipulationGrowNames";
//小儿推拿手法
var manipulationSon = "manipulationSonNames";

let DiseaseUtility = function (tokenClients, serviceAddr) {
  tokenClient = tokenClients;
  Services_Drug = serviceAddr.Drug;
  Services_Disease = serviceAddr.Disease;
  //session判断
  {
    //药品数据不存在 重新读取数据库
    if (
      !store.state.localData.drugNames ||
      // !sessionStorage.getItem(composition) ||
      // !sessionStorage.getItem(food) ||
      !store.state.localData.herbalNames
      // !sessionStorage.getItem(material)
    ) {
      DrugName();
    }
  }

  /**
   * 药品库数据
   * @param {*} keyword
   * @returns
   */
  this.getDrugOrHerbal = function (keyword) {
    //药品数据不存在 重新读取数据库
    if (!store.state.localData.drugNames || !store.state.localData.herbalNames) {
      DrugName();
    }
    if (keyword) {
      var drugList = [];
      JSON.parse(store.state.localData.drugNames).filter(item => {
        if (item.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
          drugList.push({
            value: item.name,
            id: item.result,
            type: item.type,
          });
        }
      });
      var herbalList = [];
      JSON.parse(store.state.localData.herbalNames).filter(item => {
        if (item.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
          drugList.push({
            value: item.name,
            id: item.result,
            type: item.type,
          });
        }
      });
      var result = $.merge(drugList, herbalList);
      return result;
    }
  };
};
export { DiseaseUtility };
//读取疾病数据库 将数据存入缓存
function DiseaseName() {
  var extraDomain = new Extra(tokenClient, Services_Disease);
  extraDomain.OtherNames(
    function (data) {
      sessionStorage.setItem(disease, JSON.stringify(data.data.disease));
      sessionStorage.setItem(acupoint, JSON.stringify(data.data.acupoint));
      sessionStorage.setItem(acupointSon, JSON.stringify(data.data.acupointSon));
      sessionStorage.setItem(meridian, JSON.stringify(data.data.meridian));
      sessionStorage.setItem(manipulationGrow, JSON.stringify(data.data.manipulationGrow));
      sessionStorage.setItem(manipulationSon, JSON.stringify(data.data.manipulationSon));
    },
    function (error) {
      console.log("疾病库请求异常:", error);
    }
  );
}

//读取药品数据库 将数据存入缓存
function DrugName(name) {
  var drugExtendDomain = new DrugExtend(tokenClient, Services_Drug);
  drugExtendDomain.DrugsName(
    name,
    function (data) {
      return data.data;
      //   store.commit("localData/composition", JSON.stringify(data.data.composition));
      //   store.commit("localData/drugNames", JSON.stringify(data.data.drug));
      //   store.commit("localData/herbalNames", JSON.stringify(data.data.herbal));
      //   localStorage.setItem("messageStore", JSON.stringify(store.state));
      // sessionStorage.setItem(
      // 	composition,
      // 	JSON.stringify(data.data.composition)
      // )
      // sessionStorage.setItem(drug, JSON.stringify(data.data.drug))
      // sessionStorage.setItem(food, JSON.stringify(data.data.food))
      // sessionStorage.setItem(herbal, JSON.stringify(data.data.herbal))
      // sessionStorage.setItem(material, JSON.stringify(data.data.material))
    },
    function (error) {
      return null;
      console.log("药品库请求异常:", error);
    }
  );
}

export function getDrugOrHerbal(keyword) {
  //药品数据不存在 重新读取数据库
  // if (store.state.localData.drugNames.length==0 || store.state.localData.herbalNames.length==0) {
  let res = DrugName(keyword);
  console.log(res);
  // return
  // }
  //   if (keyword) {
  //     var drugList = [];
  //     JSON.parse(store.state.localData.drugNames).filter(item => {
  //       if (item.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
  //         drugList.push({
  //           value: item.name,
  //           id: item.result,
  //           type: item.type,
  //         });
  //       }
  //     });
  //     var herbalList = [];
  //     JSON.parse(store.state.localData.herbalNames).filter(item => {
  //       if (item.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
  //         drugList.push({
  //           value: item.name,
  //           id: item.result,
  //           type: item.type,
  //         });
  //       }
  //     });
  //     var result = $.merge(drugList, herbalList);
  //     console.log(result);
  //     return result;
  //   }
}
// 格式化内容
export function replaceContent(content) {
  content = content.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "<a href=\"javascript:;\" onclick=\"getPageInfo('$1','$2','$3')\">$1</a>");
  return content;
}
export function checkNameAndPath(type) {
  var map = {
    typeName: "",
    path: "",
  };
  switch (type) {
    case "Disease":
      map.typeName = "疾病";
      map.path = "/disease/DiseaseDetail";
      break;
    case "Drug":
      map.typeName = "药品";
      map.path = "/Drug/DrugDetail";
      break;
    case "Material":
      map.typeName = "中药饮片";
      map.path = "/Drug/MaterialDetail";
      break;
    case "Herbal":
      map.typeName = "中药方剂";
      map.path = "/Drug/HerbalDetail";
      break;
    case "Food":
      map.typeName = "食疗药膳";
      map.path = "/Drug/FoodDetail";
      break;
    case "Meridian":
      map.typeName = "经络穴位";
      map.path = "/disease/MeridianDetail";
      break;
    case "Acupoint":
      map.typeName = "经络穴位";
      map.path = "/disease/MeridianDetail";
      break;
    case "Manipulation":
      map.typeName = "推拿手法";
      map.path = "/disease/MeridianDetail";
      break;
  }
  return map;
}
/**
 * 跳转详情页
 * @param {} path
 * @param {*} hashKey
 */
export function toDetailInfo(path, hashKey, type) {
  var query = {
    hashKey: hashKey,
  };
  if (type) {
    query = {
      hashKey: hashKey,
      type: type,
    };
  }
  router.push({
    path: path,
    query: query,
  });
}
/**
 * 跳转详情页
 * @param {} path
 * @param {*} hashKey
 */
export function toDetailInfoOpenTab(path, hashKey, type) {
  var query = {
    hashKey: hashKey,
  };
  if (type) {
    const href = router.resolve({
      path: path,
      query: query,
    });
    window.open(href.href, "_blank");
  }
}
/**
 * 跳转搜索页
 * @param {*} str
 * @param {*} name
 */
export function toSearchList(str, name) {
  // router.push({
  // 	path: '/searchList',
  // 	query: {
  // 		activeName: str,
  // 		keyWord: name
  // 	},
  // });
  const href = router.resolve({
    path: "/searchList",
    query: {
      activeName: str,
      keyWord: name,
    },
  });
  window.open(href.href, "_blank");
}
