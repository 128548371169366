/*
 * @Author: szs
 * @Date: 2021-06-07 15:34:41
 * @LastEditTime: 2021-07-14 18:47:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qianyue\src\components\company\Message.js
 */
let MessageDomain = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取所有消息通知
     * @param {any} success
     * @param {any} error
     */
    this.AllMessage = function (type, pageIndex, keyword, success, error) {
        var url = ServiceBaseAddress + '/api/Message/GetMessageList/0/' + type + '/' + pageIndex + '/' + keyword;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取所有消息通知
     * @param {any} MessageId
     * @param {any} success
     * @param {any} error
     */
    this.MessageDetails = function (MessageId, success, error) {

        var url = ServiceBaseAddress + '/api/Message/' + MessageId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
     /**
     * 获取所有首页消息通知
     * @param {any} success
     * @param {any} error
     */
     this.GetHomeMessage = function ( success, error) {

        var url = ServiceBaseAddress + '/api/Message/GetHomeMessage/1';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.GetHomeMessageP = function (keyword, pageIndex,pagesize,success, error) {

        var url = ServiceBaseAddress + '/api/Activity/GetActivityListPage/' + keyword + '/' + pageIndex + '/' + pagesize;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.GetpingMessageDetail = function (id,success, error) {
        var url = ServiceBaseAddress + '/api/Activity/GetActivityInfo/' + id;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

}
export {
    MessageDomain
}