<template>
  <div class="touch-box flex-space-between">
    <div class="left-div">
      <v-touch @swipeleft="onSwipeLeft">
        <i class="el-icon-caret-left" @click="onSwipeLeft"></i>
      </v-touch>
    </div>
    <div class="right-div">
      <v-touch tag="a" @swiperight="onSwipeRight">
        <i class="el-icon-caret-right" @click="onSwipeRight"></i></v-touch>
    </div>
  </div>
</template>

<script>
export default {
  name: "touch",
  data() {
    return {
    };
  },
  methods: {
    onSwipeLeft() {
      history.go(-1);
    },
    onSwipeRight() {
      history.go(+1);
    },

  },
  mounted() { },
};
</script>

<style scoped>
.touch-box {
  position: fixed;
  width: 95vw;
  left: 2.5vw;
  top: 35vh;
  font-size: 40px;
  z-index: 0;
}

.touch-box .left-div {
  position: fixed;
  left: 2.5vw;
  top: 35vh;
  font-size: 40px;
  z-index: 99999;
}

.touch-box .right-div {
  position: fixed;
  right: 2.5vw;
  top: 35vh;
  font-size: 40px;
  z-index: 99999;
}
</style>

